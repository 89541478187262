@import "@/assets/scss/style.scss";

.calendar-top-bar {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 0 24px;
    align-items: center;
    & div {
        display: flex;
        align-items: center;
        & span {
            vertical-align: baseline;
            margin-right: 10px;
        }
    }
}

.calendar-resources {
    .action-btn {
        margin: 0 5px;
    }

    ::v-deep .ant-table {
        overflow: auto;
    }
    ::v-deep .ant-table-content {
        min-width: max-content;
    }
}
